import { webToCase } from "../../../service/CreateWeb2Case";
import { REFRESH_ACCESS_TOKEN } from "../auth/auth.actions.type";
import { SEND_SUPPORT_REQUEST } from "./support.actions.type";

const actions = {
  async [SEND_SUPPORT_REQUEST]({ dispatch }, data) {
    await dispatch(REFRESH_ACCESS_TOKEN);
    const support = {
      ...data,
      orgid: "00D2p000000Pp9f",
      "00N2p000007jWll": "BioObserver",
      origin: "Web",
    };
    webToCase(support);
  },
};

export default {
  actions,
};
