import { PROCESS_STATUS } from "./ProcessStatus";
import Video from "./Video";

export default class Process {
  constructor(
    anId,
    aName,
    aKeywords,
    aDescription,
    aStatus,
    video,
    aCreatedAt
  ) {
    this.status = aStatus;
    this.video = video;
    this.createdAt = aCreatedAt;
    this.id = anId;
    this.name = aName;
    this.keywords = aKeywords;
    this.description = aDescription;
  }

  static create(anId, aName, aKeywords, aDescription) {
    return new Process(
      anId,
      aName,
      aKeywords,
      aDescription,
      PROCESS_STATUS.PENDING,
      Video.empty(),
      new Date()
    );
  }

  getKeywordsAsString() {
    return this.keywords.join(", ");
  }

  canBeDeleted() {
    return (
      this.status === PROCESS_STATUS.REJECTED ||
      this.status === PROCESS_STATUS.VALIDATED ||
      this.status === PROCESS_STATUS.FAILED ||
      this.status === PROCESS_STATUS.FINISHED
    );
  }

  paid() {
    this.status = PROCESS_STATUS.PAID;
  }

  isFinished() {
    return (
      this.status === PROCESS_STATUS.REJECTED ||
      this.status === PROCESS_STATUS.FAILED ||
      this.status === PROCESS_STATUS.PAID ||
      this.status === PROCESS_STATUS.PROCESSING ||
      this.status === PROCESS_STATUS.FINISHED
    );
  }
}
