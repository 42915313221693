import { storeInstance } from "../store";
import { REFRESH_ACCESS_TOKEN } from "../store/modules/auth/auth.actions.type";
import { GET_PROFILE } from "../store/modules/auth/auth.getters.type";

export async function guardUserRole(to, from, next) {
  try {
    await storeInstance.dispatch(REFRESH_ACCESS_TOKEN);
    const profile = storeInstance.getters[GET_PROFILE];
    if (profile !== null) {
      next();
      return;
    }
  } catch {
    next({ name: "login", params: to.params });
    return;
  }
  next({ name: "login", params: to.params });
}

export function guardRole(to, from, next) {
  const profile = storeInstance.getters[GET_PROFILE];
  const role = to.meta.middleware;
  if (profile?.hasRole(role)) {
    next();
  } else {
    next({ name: "login", params: to.params });
  }
}
