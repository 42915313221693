import { parseData } from "./parse.service";

export default function (client) {
  async function getProcessById(aUserId, aProcessId) {
    const { data } = await client.get(
      `/users/${aUserId}/processes/${aProcessId}`
    );
    return parseData.process(data.process);
  }

  async function getBinaryLink(aUserId, aProcessId) {
    const { data } = await client.get(
      `/users/${aUserId}/processes/${aProcessId}/binary`
    );
    return data.url;
  }

  async function getVideoLink(aUserId, aProcessId) {
    const { data } = await client.get(
      `/users/${aUserId}/processes/${aProcessId}/video`
    );
    return data.url;
  }

  async function download(url, filename) {
    const response = await fetch(url);
    const blob = await response.blob();
    const objectURL = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = objectURL;
    link.download = filename;
    document.body.appendChild(link); // we need to append the element to the dom -> otherwise it will not work in firefox
    link.click();
    link.remove();
  }

  async function create(aUserId, aProcess) {
    return await client.post(`/clients/${aUserId}/processes`, {
      id: aProcess.id,
      name: aProcess.name,
      description: aProcess.description,
      file: aProcess.file,
    });
  }

  async function find(aUserId) {
    const { data } = await client.get(`/users/${aUserId}/processes`);
    return parseData.processes(data.processes);
  }

  async function pay(anAccountId, aProcessId) {
    return await client.post(
      `/accounts/${anAccountId}/processes/${aProcessId}/pay`
    );
  }

  async function remove(aUserId, aProcessId) {
    return await client.delete(`/users/${aUserId}/processes/${aProcessId}`);
  }

  return {
    getProcessById,
    getBinaryLink,
    getVideoLink,
    download,
    create,
    find,
    pay,
    remove,
  };
}
