import {
  FORBIDDEN_CODE_ERROR,
  GENERIC_CODE_ERROR,
  TOKEN_EXPIRED,
} from "../config";

export const ErrorCode = {
  [GENERIC_CODE_ERROR]: "error.api.unknown",
  100: "error.api.invalid_credentials",
  101: "error.api.user_not_active",
  102: "error.api.user_already_active",
  103: "error.api.user_not_found",
  104: "error.api.user_cannot_delete_it_self",
  [TOKEN_EXPIRED]: "error.api.token_expired",
  106: "error.api.token_mismatch",
  107: "error.api.token_not_found",
  108: "error.api.invalid_credentials",
  109: "error.api.token_not_found",
  202: "error.api.user_email_exist",
  203: "error.api.cannot_owner_unregister",
  302: "error.api.storage_insufficient",
  304: "error.api.time_insufficient",
  [FORBIDDEN_CODE_ERROR]: "error.api.forbidden_access",
};
