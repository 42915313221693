import { parseData } from "./parse.service";

export default function (client) {
  async function find() {
    const { data } = await client.get("/users");
    const clients = [];
    data.clients.forEach((aClient) => {
      clients[aClient.id] = parseData.client(aClient);
    });
    return clients;
  }

  async function register(aUser) {
    return await client.post("/users", {
      id: aUser.id,
      name: aUser.name,
      surname: aUser.surname,
      email: aUser.email,
      roles: aUser.roles,
      locale: aUser.locale,
    });
  }

  async function update(aUser) {
    return await client.put(`/users/${aUser.id}`, {
      name: aUser.name,
      surname: aUser.surname,
      email: aUser.email,
      roles: aUser.roles,
    });
  }

  async function updateDetails(aProfile) {
    return await client.put(`/users/${aProfile.id}/details`, {
      locale: aProfile.locale,
    });
  }

  async function remove(aUserId) {
    return await client.delete(`/users/${aUserId}`);
  }

  return {
    find,
    update,
    updateDetails,
    register,
    remove,
  };
}
