import { DEFAULT_LOCALE } from "../config";
import { i18n } from "../i18n";

export function guardLocaleRule(to, from, next) {
  const locale = to.params.lang;
  if (i18n.global.availableLocales.includes(locale)) {
    i18n.global.locale = locale;
    next();
  } else {
    i18n.global.locale = DEFAULT_LOCALE;
    next({ params: { lang: DEFAULT_LOCALE } });
  }
}
