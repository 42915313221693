import dayjs from "dayjs";

import { GENERIC_MSG_ERROR } from "../config";
import { parseData } from "./parse.service";

const byteSizes = ["Bytes", "KB", "MB", "GB", "TB"];

export const storageTransformer = {
  humanReadable(bytes) {
    if (bytes === 0) {
      return "0 Byte";
    }

    const absValue = Math.abs(parseInt(bytes));
    const i = Math.floor(Math.log(absValue) / Math.log(1024));
    const output = `${Math.round(absValue / Math.pow(1024, i))} ${
      byteSizes[i]
    }`;

    return bytes > 0 ? output : `-${output}`;
  },
};

export const dateTransformer = {
  toDate(date) {
    return new Date(date);
  },

  toString(date) {
    if (date instanceof Date) {
      return dayjs(date).format("DD/MM/YYYY HH:mm:ss");
    }
    throw new Error("Date cannot be transformed to string");
  },

  toDateString(date) {
    if (date instanceof Date) {
      return dayjs(date).format("DD/MM/YYYY");
    }
    throw new Error("Date cannot be transformed to string");
  },

  secondsToMinutesRoundUp(seconds) {
    const minutes = Math.ceil(Math.abs(seconds) / 60);
    return seconds >= 0 ? minutes : -minutes;
  },

  secondsToMinutesRoundDown(seconds) {
    const minutes = Math.floor(Math.abs(seconds) / 60);
    return seconds >= 0 ? minutes : -minutes;
  },
};

export function sleepFor(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export function extractJwtData(JWToken) {
  let token;
  try {
    token = parseData.jwtClaims(JWToken);
  } catch (e) {
    throw new Error(GENERIC_MSG_ERROR);
  }
  return {
    profile: parseData.profile(token),
    token: parseData.token(JWToken, token.exp),
  };
}
