import { API_BACKEND_URL, IS_DEBUG } from "./config";
import account from "./service/account.service";
import apiService from "./service/api.service";
import auth from "./service/auth.service";
import process from "./service/process.service";
import session from "./service/session.service";
import statement from "./service/statement.service";
import user from "./service/user.service";

apiService.init(API_BACKEND_URL);

export const container = {
  api: {
    auth: auth(apiService),
    account: account(apiService),
    user: user(apiService),
    process: process(apiService),
    statement: statement(apiService),
  },
  httpRequester: apiService,
  session,
  debug: IS_DEBUG,
};
