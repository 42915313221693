export default class Operation {
  constructor(id, type, provider, description, time, storage, occurredOn) {
    this.id = id;
    this.provider = provider;
    this.description = description;
    this.time = time;
    this.storage = storage;
    this.type = type;
    this.occurredOn = occurredOn;
  }
}
