export default class Profile {
  constructor(id, owner, name, surname, email, roles, aLocate) {
    this.id = id;
    this.owner = owner;
    this.name = name;
    this.surname = surname;
    this.email = email;
    this.roles = roles;
    this.locale = aLocate;
  }

  hasRole(aRole) {
    return this.roles.filter((role) => role === aRole).length !== 0;
  }

  changeLocale(aLocale) {
    return new Profile(
      this.id,
      this.owner,
      this.name,
      this.surname,
      this.email,
      this.roles,
      aLocale
    );
  }
}
