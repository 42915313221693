import { dateTransformer } from "../service/utils";

export default class Balance {
  constructor(timeUsed, timeAvailable, usedSpace, maxSpace) {
    this.timeUsed = timeUsed;
    this.timeAvailable = timeAvailable;
    this.usedSpace = usedSpace;
    this.maxSpace = maxSpace;
    this.remainTime = this.timeAvailable - this.timeUsed;
    this.remainTimePercentage = Math.round(
      (this.remainTime * 100) / this.timeAvailable
    );
    this.availableSpace = this.maxSpace - this.usedSpace;
    this.availableSpacePercentage = Math.round(
      (this.availableSpace * 100) / this.maxSpace
    );
  }

  timeUsedInMinutes() {
    return dateTransformer.secondsToMinutesRoundUp(this.timeUsed);
  }

  timeAvailableInMinutes() {
    return dateTransformer.secondsToMinutesRoundDown(this.timeAvailable);
  }

  timeRemainInMinutes() {
    return this.timeAvailableInMinutes() - this.timeUsedInMinutes();
  }
}
