import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";

dayjs.extend(dayjsPluginUTC);

export const TOAST_LIFE = 3 * 1000;
export const VIDEO_PROCESS_FETCHING = 4000;
export const FETCHING_DELAY = 2000;
export const DEFAULT_LOCALE = "en";

export const VALID_AUTH_SCOPE = "BioObserver";
export const IS_DEBUG = process.env.NODE_ENV !== "production";
export const API_BACKEND_URL = process.env.VUE_APP_BACKEND_URL;
export const GENERIC_CODE_ERROR = 0;
export const TOKEN_EXPIRED = 105;
export const FORBIDDEN_CODE_ERROR = 2001;
export const GENERIC_MSG_ERROR = "error.api.unknown";
