import { dateTransformer, storageTransformer } from "../service/utils";

export default class Video {
  constructor(aFilename, aDuration, aFormat, aSize, resolution, frame) {
    this.filename = aFilename;
    this.format = aFormat;
    this.duration = aDuration;
    this.size = aSize;
    this.resolution = resolution;
    this.frame = frame;
  }

  static empty() {
    return new Video("", 0, "", "", "", "");
  }

  humanReadableSize() {
    return storageTransformer.humanReadable(this.size);
  }

  durationInMinutes() {
    return dateTransformer.secondsToMinutesRoundUp(this.duration);
  }

  getFrame() {
    return this.frame === ""
      ? require("@/assets/frame-placeholder.jpg")
      : `data:image/jpeg;base64,${this.frame}`;
  }
}
