import { container } from "../../../container";
import { extractJwtData } from "../../../service/utils";
import { EXECUTE_REQUEST } from "../global/global.actions.type";
import { SET_USERS } from "../user/user.mutations.type";
import {
  REFRESH_ACCESS_TOKEN,
  USER_ACTIVATION,
  USER_CHANGE_PASSWORD,
  USER_FORGET_PASSWORD,
  USER_LOGIN,
  USER_LOGOUT,
  USER_RESET_PASSWORD,
} from "./auth.actions.type";
import {
  AUTHORIZED,
  GET_ACCESS_TOKEN,
  GET_FULL_NAME,
  GET_PROFILE,
} from "./auth.getters.type";
import {
  CLEAN_AUTH,
  SET_ACCESS_TOKEN,
  SET_PROFILE,
} from "./auth.mutations.type";

const service = container.api.auth;
const { httpRequester, session } = container;

const state = {
  profile: null,
  accessToken: null,
};

const actions = {
  async [USER_LOGIN]({ commit, dispatch }, credentials) {
    const { access, refresh } = await dispatch(
      EXECUTE_REQUEST,
      service.login(credentials)
    );
    session.saveRefreshToken(refresh);
    const { token, profile } = extractJwtData(access);
    commit(SET_PROFILE, profile);
    commit(SET_ACCESS_TOKEN, token);
  },

  async [REFRESH_ACCESS_TOKEN]({ commit, state }) {
    if (state.accessToken == null || state.accessToken.isExpired(new Date())) {
      const refreshToken = session.getRefreshToken();
      const { access, refresh } = await service.refresh(refreshToken.token);
      session.saveRefreshToken(refresh);
      const { token, profile } = extractJwtData(access);
      commit(SET_PROFILE, profile);
      commit(SET_ACCESS_TOKEN, token);
    }
  },

  async [USER_LOGOUT]({ commit, dispatch }, credentials) {
    await dispatch(REFRESH_ACCESS_TOKEN);
    await dispatch(EXECUTE_REQUEST, service.logout(credentials));
    commit(CLEAN_AUTH);
    commit(SET_USERS, []);
  },

  async [USER_CHANGE_PASSWORD]({ dispatch }, passwords) {
    await dispatch(EXECUTE_REQUEST, service.changePassword(passwords));
  },

  async [USER_ACTIVATION]({ dispatch }, activation) {
    await dispatch(EXECUTE_REQUEST, service.activation(activation));
  },

  async [USER_FORGET_PASSWORD]({ dispatch }, username) {
    await dispatch(EXECUTE_REQUEST, service.forgetPassword(username));
  },

  async [USER_RESET_PASSWORD]({ dispatch }, reset) {
    await dispatch(EXECUTE_REQUEST, service.resetPassword(reset));
  },
};

const getters = {
  [AUTHORIZED]: (state) => !state.accessToken?.isExpired(),

  [GET_ACCESS_TOKEN]: (state) => state.accessToken.token,

  [GET_PROFILE]: (state) => state.profile,

  [GET_FULL_NAME]: (state) => `${state.profile.name} ${state.profile.surname}`,
};

const mutations = {
  async [SET_PROFILE](state, aProfile) {
    state.profile = aProfile;
  },

  [SET_ACCESS_TOKEN](state, accessToken) {
    state.accessToken = accessToken;
    httpRequester.changeToken(accessToken.token);
  },

  [CLEAN_AUTH](state) {
    state.profile = null;
    state.accessToken = null;
    httpRequester.changeToken("");
    session.clear();
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
