import { storageTransformer } from "../service/utils";

export default class OperationStorage {
  constructor(variation, available) {
    this.variation = variation;
    this.available = available;
  }

  isPositive() {
    return this.variation > 0;
  }

  humanReadableVariation() {
    if (this.variation === 0) {
      return "-";
    }
    return storageTransformer.humanReadable(this.variation);
  }

  humanReadableAvailable() {
    if (this.variation === 0) {
      return "-";
    }
    return storageTransformer.humanReadable(this.available);
  }
}
