import { container } from "../../../container";
import { REFRESH_ACCESS_TOKEN } from "../auth/auth.actions.type";
import { EXECUTE_REQUEST } from "../global/global.actions.type";
import { FETCHING_STATEMENTS } from "./statement.actions.type";
import { GET_OPERATIONS, GET_STATEMENTS } from "./statement.getters.type";
import { SET_STATEMENTS } from "./statement.mutations.type";

const service = () => container.api.statement;

const state = {
  statements: [],
};

const actions = {
  async [FETCHING_STATEMENTS]({ commit, dispatch, rootState }) {
    await dispatch(REFRESH_ACCESS_TOKEN);
    const request = service().fetch(rootState.auth.profile.owner);
    const processes = await dispatch(EXECUTE_REQUEST, request);
    commit(SET_STATEMENTS, processes);
  },
};

const getters = {
  [GET_STATEMENTS]: (state) => state.statements,

  [GET_OPERATIONS]: (state) => (date) => {
    return state.statements.filter((statement) => statement.date === date)[0];
  },
};

const mutations = {
  [SET_STATEMENTS](state, aStatements) {
    state.statements = aStatements;
  },
};
export default {
  state,
  actions,
  getters,
  mutations,
};
