import { createLogger, createStore } from "vuex";

import { container } from "../container";
import account from "./modules/account/account.module";
import auth from "./modules/auth/auth.module";
import global from "./modules/global/global.module";
import process from "./modules/process/process.module";
import statement from "./modules/statement/statement.module";
import support from "./modules/support/support.module";
import user from "./modules/user/user.module";

export const store = {
  modules: {
    account,
    auth,
    global,
    process,
    statement,
    support,
    user,
  },
};

const plugins = container.debug ? [createLogger({})] : [];

export const storeInstance = createStore({
  plugins,
  modules: store.modules,
});
