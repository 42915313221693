import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "prismjs/themes/prism-coy.css";
import "./assets/layout/layout.scss";

import AutoComplete from "primevue/autocomplete";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import Card from "primevue/card";
import Checkbox from "primevue/checkbox";
import Chips from "primevue/chips";
import Column from "primevue/column";
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import DataTable from "primevue/datatable";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import FileUpload from "primevue/fileupload";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import Menu from "primevue/menu";
import Message from "primevue/message";
import Paginator from "primevue/paginator";
import Panel from "primevue/panel";
import Password from "primevue/password";
import ProgressBar from "primevue/progressbar";
import RadioButton from "primevue/radiobutton";
import Rating from "primevue/rating";
import Ripple from "primevue/ripple";
import Sidebar from "primevue/sidebar";
import Steps from "primevue/steps";
import Textarea from "primevue/textarea";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";
import { createApp, reactive } from "vue";

import App from "./App.vue";
import CodeHighlight from "./AppCodeHighlight";
import { i18n } from "./i18n";
import router from "./router";
import ErrorService from "./service/error.service";
import { storeInstance } from "./store";

const app = createApp(App);

app.config.errorHandler = (error) => ErrorService.onError(error); // Handle all Vue errors
app.config.globalProperties.$appState = reactive({ inputStyle: "outlined" });
app.config.globalProperties.$primevue = reactive({ ripple: true });

app.use(i18n);
app.use(router);
app.use(storeInstance);
app.use(PrimeVue);
app.use(ToastService);
app.use(ConfirmationService);

app.directive("ripple", Ripple);
app.directive("code", CodeHighlight);
app.directive("tooltip", Tooltip);

app.component("AutoComplete", AutoComplete);
app.component("Button", Button);
app.component("Calendar", Calendar);
app.component("Card", Card);
app.component("Checkbox", Checkbox);
app.component("Chips", Chips);
app.component("Column", Column);
app.component("DataTable", DataTable);
app.component("Divider", Divider);
app.component("Dropdown", Dropdown);
app.component("FileUpload", FileUpload);
app.component("InputNumber", InputNumber);
app.component("InputSwitch", InputSwitch);
app.component("InputText", InputText);
app.component("Menu", Menu);
app.component("Message", Message);
app.component("Paginator", Paginator);
app.component("Panel", Panel);
app.component("Password", Password);
app.component("ProgressBar", ProgressBar);
app.component("RadioButton", RadioButton);
app.component("Rating", Rating);
app.component("Sidebar", Sidebar);
app.component("Steps", Steps);
app.component("Textarea", Textarea);
app.component("Toast", Toast);

app.mount("#app");
