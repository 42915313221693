import Cookies from "js-cookie";

import Token from "../models/auth/Token";
import { parseData } from "./parse.service";

const REFRESH_TOKEN = "refresh_token";
const REFRESH_EXPIRATION = "refresh_exp";

export default {
  saveRefreshToken(refreshToken) {
    const refresh = parseData.jwtClaims(refreshToken);
    Cookies.set(REFRESH_TOKEN, refreshToken);
    Cookies.set(REFRESH_EXPIRATION, refresh.exp);
  },

  getRefreshToken() {
    return new Token(
      Cookies.get(REFRESH_TOKEN),
      Cookies.get(REFRESH_EXPIRATION)
    );
  },

  clear() {
    Cookies.remove(REFRESH_TOKEN);
    Cookies.remove(REFRESH_EXPIRATION);
  },
};
