import { ulid } from "ulid";

import { Role } from "./Role";

export default class User {
  constructor(anId, aName, aSurname, anEmail, aRoles) {
    this.id = anId;
    this.name = aName;
    this.surname = aSurname;
    this.email = anEmail;
    this.roles = aRoles;
  }

  static empty() {
    return new User(ulid(), "", "", "", [Role.user]);
  }

  fullName() {
    return `${this.name} ${this.surname}`;
  }
}
