import { Role } from "@/models/Role";

import { guardLocaleRule } from "./guardLocaleRule";
import { guardRole, guardUserRole } from "./guardRoleRule";

export const routes = [
  {
    path: "/",
    redirect: "en",
  },
  {
    path: "/:lang",
    beforeEnter: guardLocaleRule,
    component: () => import("@/views/Lang.vue"),
    children: [
      {
        path: "",
        name: "login",
        component: () => import("@/views/Login.vue"),
      },
      {
        path: "activation/:username/:token",
        name: "activation",
        component: () => import("@/views/Activation"),
      },
      {
        path: "forgot-password",
        name: "forgot-password",
        component: () => import("@/views/ForgotPassword"),
      },
      {
        path: "reset-password/:username/:token",
        name: "reset-password",
        component: () => import("@/views/ResetPassword"),
      },
      {
        path: "panel",
        name: "panel",
        beforeEnter: guardUserRole,
        component: () => import("@/views/panel/Layout"),
        children: [
          {
            path: "",
            name: "dashboard",
            component: () => import("@/views/panel/Dashboard"),
          },
          {
            path: "dashboard",
            name: "dashboard",
            component: () => import("@/views/panel/Dashboard"),
          },
          {
            path: "processes",
            name: "processes",
            component: () => import("@/views/panel/Processes"),
          },
          {
            path: "process/steps",
            name: "new-process",
            component: () => import("@/views/panel/VideoSteps.vue"),
            children: [
              {
                path: "upload",
                name: "upload-video",
                component: () =>
                  import("@/components/videos/StepVideoUpload.vue"),
              },
              {
                path: "verify",
                name: "verify-video",
                props: true,
                component: () =>
                  import("@/components/videos/StepVideoVerify.vue"),
              },
              {
                path: "payment",
                name: "payment-video",
                props: true,
                component: () =>
                  import("@/components/videos/StepVideoPayment.vue"),
              },
              {
                path: "finished/:processId",
                name: "finished-video",
                props: true,
                component: () =>
                  import("@/components/videos/StepVideoFinished.vue"),
              },
            ],
          },
          {
            path: "users",
            name: "users",
            beforeEnter: guardRole,
            meta: { middleware: Role.admin },
            component: () => import("@/views/panel/Users.vue"),
          },
          {
            path: "statement",
            name: "statement",
            beforeEnter: guardRole,
            meta: { middleware: Role.admin },
            component: () => import("@/views/panel/Statement.vue"),
          },
          {
            path: "account",
            name: "account",
            beforeEnter: guardRole,
            meta: { middleware: Role.owner },
            component: () => import("@/views/panel/AccountSettings.vue"),
          },
          {
            path: "profile",
            name: "profile",
            component: () => import("@/views/panel/Profile.vue"),
          },
          {
            path: "contact-us",
            name: "contact-us",
            component: () => import("@/views/panel/ContactUs.vue"),
          },
          {
            path: "help",
            name: "help",
            component: () => import("@/views/panel/Help.vue"),
          },
          {
            path: ":patchMatch(.*)",
            component: () => import("@/views/panel/EmptySection.vue"),
          },
        ],
      },
      {
        path: ":patchMatch(.*)",
        redirect: () => {
          return { name: "login" };
        },
      },
    ],
  },
];
