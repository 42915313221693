import { parseData } from "./parse.service";

export default function (client) {
  async function get(anId) {
    const { data } = await client.get(`/accounts/${anId}`);
    return parseData.account(data.account);
  }

  async function balance(anAccountId) {
    const { data } = await client.get(`/accounts/${anAccountId}/balance`);
    return parseData.balance(data.balance);
  }

  async function updateBilling(anAccount) {
    return await client.put(`/accounts/${anAccount.id}/billing`, {
      name: anAccount.name,
      line1: anAccount.address.line1,
      line2: anAccount.address.line2,
      city: anAccount.address.city,
      postcode: anAccount.address.postcode,
      country: anAccount.address.country,
    });
  }

  async function updateContact(anAccount) {
    return await client.put(`/accounts/${anAccount.id}/contact`, {
      name: anAccount.contact.name,
      surname: anAccount.contact.surname,
      email: anAccount.contact.email,
    });
  }

  async function unregister(anId) {
    return await client.delete(`/accounts/${anId}`);
  }

  return {
    get,
    balance,
    updateContact,
    updateBilling,
    unregister,
  };
}
