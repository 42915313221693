import { container } from "../../../container";
import { REFRESH_ACCESS_TOKEN } from "../auth/auth.actions.type";
import { CLEAN_AUTH } from "../auth/auth.mutations.type";
import { EXECUTE_REQUEST } from "../global/global.actions.type";
import { SET_USERS } from "../user/user.mutations.type";
import {
  FETCHING_ACCOUNT,
  FETCHING_LAST_BALANCE,
  UNREGISTER_ACCOUNT,
  UPDATE_ACCOUNT_BILLING,
  UPDATE_ACCOUNT_CONTACT,
} from "./account.actions.type";
import { GET_ACCOUNT, GET_LAST_BALANCE } from "./account.getters.type";
import { SET_ACCOUNT, SET_LAST_BALANCE } from "./account.mutations.type";

const service = container.api.account;

const state = {
  account: null,
  balance: null,
};

const actions = {
  async [FETCHING_ACCOUNT]({ commit, dispatch, rootState }) {
    await dispatch(REFRESH_ACCESS_TOKEN);
    const accountId = rootState.auth.profile.owner;
    const account = await dispatch(EXECUTE_REQUEST, service.get(accountId));
    commit(SET_ACCOUNT, account);
    commit(SET_USERS, account.users);
  },

  async [FETCHING_LAST_BALANCE]({ commit, dispatch, rootState }) {
    await dispatch(REFRESH_ACCESS_TOKEN);
    const accountId = rootState.auth.profile.owner;
    const balance = await dispatch(EXECUTE_REQUEST, service.balance(accountId));
    commit(SET_LAST_BALANCE, balance);
  },

  async [UPDATE_ACCOUNT_BILLING]({ commit, dispatch }, anAccount) {
    await dispatch(REFRESH_ACCESS_TOKEN);
    await dispatch(EXECUTE_REQUEST, service.updateBilling(anAccount));
    commit(SET_ACCOUNT, anAccount);
  },

  async [UPDATE_ACCOUNT_CONTACT]({ commit, dispatch }, anAccount) {
    await dispatch(REFRESH_ACCESS_TOKEN);
    await dispatch(EXECUTE_REQUEST, service.updateContact(anAccount));
    commit(SET_ACCOUNT, anAccount);
  },

  async [UNREGISTER_ACCOUNT]({ commit, dispatch, rootState }) {
    await dispatch(REFRESH_ACCESS_TOKEN);
    const accountId = rootState.auth.profile.owner;
    await dispatch(EXECUTE_REQUEST, service.unregister(accountId));
    commit(CLEAN_AUTH);
  },
};

const getters = {
  [GET_ACCOUNT]: (state) => state.account,
  [GET_LAST_BALANCE]: (state) => state.balance,
};

const mutations = {
  [SET_ACCOUNT](state, anAccount) {
    state.account = anAccount;
  },
  [SET_LAST_BALANCE](state, aBalance) {
    state.balance = aBalance;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
