import { API_BACKEND_URL } from "../../../config";
import { container } from "../../../container";
import { REFRESH_ACCESS_TOKEN } from "../auth/auth.actions.type";
import { EXECUTE_REQUEST } from "../global/global.actions.type";
import {
  DECREASE_LOADING_STATUS,
  INCREASE_LOADING_STATUS,
} from "../global/global.mutations.type";
import {
  CREATE_PROCESS,
  DELETING_PROCESS,
  DOWNLOAD_BINARY,
  DOWNLOAD_VIDEO,
  DOWNLOAD_VIEWER,
  FETCHING_PROCESS_BY_ID,
  FETCHING_PROCESSES,
  PAY_PROCESS,
} from "./process.actions.type";
import {
  COUNT_PROCESSES,
  GET_PROCESS_BY_ID,
  GET_PROCESSES,
  GET_VIDEO_UPLOAD_URL,
} from "./process.getters.type";
import {
  DELETE_PROCESS,
  SET_PROCESS,
  SET_PROCESS_PAID,
  SET_PROCESSES,
} from "./process.mutations.type";

const service = () => container.api.process;

const state = {
  processes: [],
  count: null,
};

const actions = {
  async [FETCHING_PROCESSES]({ commit, dispatch, rootState }) {
    await dispatch(REFRESH_ACCESS_TOKEN);
    const request = service().find(rootState.auth.profile.id);
    const processes = await dispatch(EXECUTE_REQUEST, request);
    commit(SET_PROCESSES, processes);
  },
  async [FETCHING_PROCESS_BY_ID]({ commit, dispatch, rootState }, aProcessId) {
    await dispatch(REFRESH_ACCESS_TOKEN);
    const process = await service().getProcessById(
      rootState.auth.profile.id,
      aProcessId
    );
    commit(SET_PROCESS, process);
  },
  async [PAY_PROCESS]({ commit, dispatch, rootState }, aProcessId) {
    await dispatch(REFRESH_ACCESS_TOKEN);
    const request = service().pay(rootState.auth.profile.owner, aProcessId);
    await dispatch(EXECUTE_REQUEST, request);
    commit(SET_PROCESS_PAID, aProcessId);
  },
  async [DELETING_PROCESS]({ commit, dispatch, rootState }, aProcessId) {
    await dispatch(REFRESH_ACCESS_TOKEN);
    const request = service().remove(rootState.auth.profile.id, aProcessId);
    await dispatch(EXECUTE_REQUEST, request);
    commit(DELETE_PROCESS, aProcessId);
  },
  async [DOWNLOAD_BINARY]({ commit, dispatch, rootState }, aProcessId) {
    await dispatch(REFRESH_ACCESS_TOKEN);
    const request = service().getBinaryLink(
      rootState.auth.profile.id,
      aProcessId
    );
    // Two process in queue. So, we force to loading until the end.
    commit(INCREASE_LOADING_STATUS);
    const url = await dispatch(EXECUTE_REQUEST, request);
    const process = state.processes[aProcessId];
    const download = service().download(url, `${process.video.filename}.bin`);
    await dispatch(EXECUTE_REQUEST, download);
    commit(DECREASE_LOADING_STATUS);
  },
  async [DOWNLOAD_VIDEO]({ commit, dispatch, rootState, state }, aProcessId) {
    await dispatch(REFRESH_ACCESS_TOKEN);
    const request = service().getVideoLink(
      rootState.auth.profile.id,
      aProcessId
    );
    // Two process in queue. So, we force to loading until the end.
    commit(INCREASE_LOADING_STATUS);
    const url = await dispatch(EXECUTE_REQUEST, request);
    const process = state.processes[aProcessId];
    await service().download(url, process.video.filename);
    commit(DECREASE_LOADING_STATUS);
  },

  async [DOWNLOAD_VIEWER]({ commit, dispatch }, url) {
    await dispatch(REFRESH_ACCESS_TOKEN);
    commit(INCREASE_LOADING_STATUS);
    await service().download(url, "BioObserver-client.exe");
    commit(DECREASE_LOADING_STATUS);
  },

  [CREATE_PROCESS]({ commit }, aProcess) {
    // It sends the request from upload video component
    commit(SET_PROCESS, aProcess);
  },
};

const getters = {
  [GET_PROCESSES]: (state) => Object.values(state.processes),

  [GET_PROCESS_BY_ID]: (state) => (processId) => {
    return state.processes[processId];
  },

  [COUNT_PROCESSES]: (state) => state.count,

  [GET_VIDEO_UPLOAD_URL]: (state, getters) =>
    `${API_BACKEND_URL}/users/${getters.getProfile.id}/processes`,
};

const mutations = {
  [SET_PROCESSES](state, aProcesses) {
    state.processes = aProcesses;
    state.count = Object.values(state.processes).length;
  },
  [SET_PROCESS](state, aProcess) {
    state.processes[aProcess.id] = aProcess;
    state.processes = { ...state.processes };
  },
  [SET_PROCESS_PAID](state, aProcessId) {
    state.processes[aProcessId].paid();
    state.processes = { ...state.processes };
  },

  [DELETE_PROCESS](state, aProcessId) {
    delete state.processes[aProcessId];
    state.count = Object.values(state.processes).length;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
