export default class Account {
  constructor(anId, aName, anAddress, aContact, aUsers) {
    this.id = anId;
    this.name = aName;
    this.address = anAddress;
    this.contact = aContact;
    this.users = aUsers;
  }

  changeBilling(aName, anAddress) {
    return new Account(this.id, aName, anAddress, this.contact, this.users);
  }

  changeContact(aContact) {
    return new Account(this.id, this.name, this.address, aContact, this.users);
  }
}
