import dayjs from "dayjs";
import jwt_decode from "jwt-decode";

import { FORBIDDEN_CODE_ERROR, VALID_AUTH_SCOPE } from "../config";
import Account from "../models/account/Account";
import Address from "../models/account/Address";
import Contact from "../models/account/Contact";
import Profile from "../models/auth/Profile";
import Token from "../models/auth/Token";
import Balance from "../models/Balance";
import Operation from "../models/Operation";
import OperationStorage from "../models/OperationStorage";
import OperationTime from "../models/OperationTime";
import Process from "../models/Process";
import Statement from "../models/Statement";
import User from "../models/User";
import Video from "../models/Video";
import { dateTransformer } from "./utils";

export const parseData = {
  account(anAccount) {
    return new Account(
      anAccount.id,
      anAccount.name,
      new Address(
        anAccount.address.line1,
        anAccount.address.line2,
        anAccount.address.city,
        anAccount.address.postcode,
        anAccount.address.country
      ),
      new Contact(
        anAccount.contact.name,
        anAccount.contact.surname,
        anAccount.contact.email
      ),
      this.users(anAccount.clients)
    );
  },

  balance(aBalance) {
    return new Balance(
      aBalance.wage.time.consumed,
      aBalance.wage.time.total,
      aBalance.wage.storage.used,
      aBalance.wage.storage.total
    );
  },

  statements(theStatements) {
    const operations = theStatements.map((statement) => {
      return new Statement(
        statement.id,
        dayjs(statement.date).format("MMMM YYYY"),
        statement.operations.map((operation) => {
          return new Operation(
            operation.id,
            operation.type,
            operation.provider,
            operation.description,
            new OperationTime(
              operation.time.variation,
              operation.time.available
            ),
            new OperationStorage(
              operation.storage.variation,
              operation.storage.available
            ),
            dateTransformer.toDate(operation.occurred_on)
          );
        })
      );
    });
    return operations.flat();
  },

  users(theUsers) {
    const users = [];
    theUsers.forEach((aClient) => {
      users[aClient.id] = new User(
        aClient.id,
        aClient.name,
        aClient.surname,
        aClient.email,
        aClient.roles
      );
    });
    return users;
  },

  profile(tokenClaims) {
    const scope = tokenClaims.scope.filter(
      (data) => data.scope === VALID_AUTH_SCOPE
    );
    if (scope.length === 0) {
      throw new Error(FORBIDDEN_CODE_ERROR);
    }
    return new Profile(
      scope[0].client,
      scope[0].account,
      tokenClaims.name,
      tokenClaims.surname,
      tokenClaims.email,
      scope[0].roles,
      tokenClaims.locale
    );
  },

  processes(data) {
    const processes = [];
    data.forEach((process) => (processes[process.id] = this.process(process)));
    return processes;
  },

  process(process) {
    return new Process(
      process.id,
      process.tags.name,
      process.tags.keywords,
      process.tags.description,
      process.status,
      new Video(
        process.video.filename,
        process.video.props.duration,
        process.video.props.format,
        process.video.props.size,
        process.video.props.resolution,
        process.video.frame
      ),
      dateTransformer.toDate(process.created_at)
    );
  },

  jwtClaims(jwtRaw) {
    try {
      return jwt_decode(jwtRaw);
    } catch (e) {
      throw new Error("Cannot parse the JWT token");
    }
  },

  token(jwtRaw, tokenExp) {
    return new Token(jwtRaw, new Date(tokenExp * 1000));
  },
};
