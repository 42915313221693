import { createI18n } from "vue-i18n";

import en from "@/locales/en.json";
import es from "@/locales/es.json";

import { DEFAULT_LOCALE } from "./config";

export const i18n = createI18n({
  locale: DEFAULT_LOCALE, // set locale
  fallbackLocale: DEFAULT_LOCALE, // set fallback locale
  messages: {
    en,
    es,
  },
});
