import { container } from "../../../container";
import { REFRESH_ACCESS_TOKEN } from "../auth/auth.actions.type";
import { SET_PROFILE } from "../auth/auth.mutations.type";
import { EXECUTE_REQUEST } from "../global/global.actions.type";
import {
  REGISTER_USER,
  UNREGISTER_USER,
  UPDATE_PROFILE,
  UPDATE_USER,
} from "./user.actions.type";
import {
  COUNT_USERS,
  GET_USER_BY_EMAIL,
  GET_USER_BY_ID,
  GET_USERS,
} from "./user.getters.type";
import { DELETE_USER, SET_USER, SET_USERS } from "./user.mutations.type";

const api = container.api.user;

const state = {
  users: [],
};

const actions = {
  async [REGISTER_USER]({ commit, dispatch, getters, rootState }, aUser) {
    if (getters.getUserByEmail(aUser.email) !== undefined) {
      throw new Error(202);
    }
    aUser.locale = rootState.auth.profile.locale;
    await dispatch(REFRESH_ACCESS_TOKEN);
    await dispatch(EXECUTE_REQUEST, api.register(aUser));
    commit(SET_USER, aUser);
  },
  async [UPDATE_USER]({ commit, dispatch }, aUser) {
    await dispatch(REFRESH_ACCESS_TOKEN);
    await dispatch(EXECUTE_REQUEST, api.update(aUser));
    commit(SET_USER, aUser);
  },
  async [UPDATE_PROFILE]({ commit, dispatch }, profile) {
    await dispatch(REFRESH_ACCESS_TOKEN);
    await dispatch(EXECUTE_REQUEST, api.updateDetails(profile));
    commit(SET_PROFILE, profile);
  },
  async [UNREGISTER_USER]({ commit, dispatch }, aUser) {
    await dispatch(REFRESH_ACCESS_TOKEN);
    await dispatch(EXECUTE_REQUEST, api.remove(aUser.id));
    commit(DELETE_USER, aUser.id);
  },
};

const getters = {
  [GET_USERS](state) {
    return Object.values(state.users);
  },
  [GET_USER_BY_ID]: (state) => (userId) => {
    return state.users[userId];
  },
  [GET_USER_BY_EMAIL]: (state) => (email) => {
    return Object.values(state.users).find((user) => user.email === email);
  },
  [COUNT_USERS](state) {
    const count = Object.values(state.users).length;
    return count === 0 ? null : count;
  },
};

const mutations = {
  [SET_USER](state, aUser) {
    state.users[aUser.id] = aUser;
    state.users = { ...state.users };
  },
  [SET_USERS](state, aUser) {
    state.users = aUser;
  },
  [DELETE_USER](state, aUserId) {
    delete state.users[aUserId];
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
