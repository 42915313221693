export default class ErrorService {
  static onError(error) {
    const { response } = error;
    if (response && response.status >= 400 && response.status < 405) {
      ErrorService.logAuth(error);
      return false;
    }
    ErrorService.logError(error);
  }

  static onWarn(error) {
    console.warn(error);
  }

  static onInfo(error) {
    console.info(error);
  }

  static onDebug(error) {
    const { response } = error;
    if (response && response.status >= 400 && response.status < 405) {
      this.logAuth(error);
      return false;
    }
    this.logError(error);
  }

  static logError(error) {
    console.error(error);
  }

  static logAuth(error) {
    console.error(error, "Auth");
  }
}
