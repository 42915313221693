import { dateTransformer } from "../service/utils";

export default class OperationTime {
  constructor(variation, available) {
    this.variation = variation;
    this.available = available;
  }

  isPositive() {
    return this.variation > 0;
  }

  variationInMinutes() {
    if (this.variation === 0) {
      return "-";
    }
    return `${dateTransformer.secondsToMinutesRoundUp(this.variation)} min.`;
  }

  availableInMinutes() {
    if (this.variation === 0) {
      return "-";
    }
    return `${dateTransformer.secondsToMinutesRoundDown(this.available)} min.`;
  }
}
