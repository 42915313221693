import { parseData } from "./parse.service";

export default function (client) {
  async function fetch(anId) {
    const { data } = await client.get(`/accounts/${anId}/statements`);
    return parseData.statements(data.statements);
  }

  return {
    fetch,
  };
}
