import { dateTransformer } from "../service/utils";

export default class Statement {
  constructor(id, date, operations) {
    this.id = id;
    this.date = date;
    this.operations = operations;
  }

  stringlyDate() {
    return dateTransformer.toDateString(this.date);
  }
}
